<script setup lang="ts">
import delve from 'dlv'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import useIsDesktop from '~/composables/useIsDesktop'

const { isDesktop } = useIsDesktop()
const { find } = useStrapi()

const { data: reviewsResponse } = await useCachedAsyncData('reviews-response', () => {
  return find('reviews')
},
)

const reviews = delve(reviewsResponse.value, 'hydra:member', '')

const currentReview = ref(null)
</script>

<template>
  <div class="review-container">
    <div class="container">
      <div class="left-side">
        <span class="advice-title">
          {{ $t('review.title') }}
        </span>
        <NuxtLink
          :to="'https://www.google.com/search?sca_esv=83d4d981e1094711&sca_upv=1&tbm=lcl&q=Plan%C3%A8te+Croisi%C3%A8re+Avis&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIxNDQxNTE2tTA2MjAxMDY2AUKLDYyMrxglAnIS8w6vKElVcC7KzyzOPLyiKFXBsSyzeBErTikADHvW0lEAAAA&rldimm=11454358320403343438&hl=fr-FR&sa=X&ved=2ahUKEwjqj9WpwPGHAxUlSaQEHQ7_KkgQ9fQKegQIIBAF&biw=1920&bih=934&dpr=1#lkt=LocalPoiReviews'"
          :target="'blank'"
        >
          {{ $t('review.see') }}
        </NuxtLink>
      </div>
      <div
        v-if="reviews"
        class="right-side"
      >
        <carousel
          class="reviews--carousel container"
          :items-to-show="isDesktop ? 3 : 1"
          :wrap-around="false"
        >
          <template
            v-for="(review, index) in reviews"
            :key="index"
          >
            <slide
              v-if="review.text"
            >
              <ReviewItem
                :review="review"
                @click="currentReview = review"
              />
            </slide>
          </template>

          <template #addons>
            <navigation>
              <template #prev>
                <UiIconsArrowWithLine direction="left" />
              </template>
              <template #next>
                <UiIconsArrowWithLine direction="right" />
              </template>
            </navigation>
            <pagination />
          </template>
        </carousel>
      </div>
    </div>

    <Teleport to="#teleports">
      <UiElementsDefaultModal v-if="currentReview !== null">
        <template #modal-content>
          {{ currentReview.text }}
        </template>
        <template #close-modal>
          <div
            class="modal-close"
            @click="currentReview = null"
          >
            <UiIconsCross />
          </div>
        </template>
      </UiElementsDefaultModal>
    </Teleport>
  </div>
</template>

<style lang="scss">
.review-container {
  background-color: var( --color-navy-0 );
  >.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 2.5rem 1.5rem;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 3.5rem;
      padding: 5rem 1.5rem;
    }
  }

  .left-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      flex-basis: 20%;
    }

    > span {
      display: inline-block;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
      align-items: start;
    }

    .advice-title {
      color: var( --color-navy-600 );
      font-family: var(--font-secondary);
      font-weight: 500;
      font-size: 1.5rem;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        font-size: 2rem;
      }
    }
  }

  .right-side {
    width: 100%;
    position: relative;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      flex-basis: 70%;
    }
  }

  .reviews--carousel {
    text-align: left;
  }
}

.reviews--carousel {
  --vc-pgn-width: 5px;
  --vc-pgn-height: 5px;
  --vc-pgn-background-color: transparent;
  --vc-pgn-active-color: var(--color-navy-600);
  --vc-pgn-margin: 0;

  .carousel__slide {
    padding: 1em;
  }

  .carousel__pagination {
    padding: 0 1em 1em;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      display: none;
    }
  }

  .carousel__pagination-item {
    padding: .5em
  }

  .carousel__pagination-button {
    border: 1px solid var(--color-navy-600);
    border-radius: 100%;
  }

  .carousel__prev, .carousel__next {
    border: 1px solid var(--color-navy-600);
    padding: .5em;
    font-size: 1em;

    top: auto;
    bottom: 0;
    transform: none;
    margin: 0 1em;

    @media (min-width: map-get($grid-breakpoints, md)) {
      background: var(--color-navy-600);
      color: white;
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
    }

    &--disabled {
      opacity: 0;
    }
  }

  .carousel__prev {
    @media (min-width: map-get($grid-breakpoints, md)) {
      left: -1.5em;
    }
  }

  .carousel__next {
    @media (min-width: map-get($grid-breakpoints, md)) {
      right: -1.5em;
    }
  }
}
</style>
