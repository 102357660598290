<script setup lang="ts">
import moment from 'moment/moment'
import type { Review } from '~/models/Review'

defineProps({
  review: {
    type: Object as PropType<Review>,
    required: true,
  },
})

function formatDate(timestamp: number) {
  const date = moment.unix(timestamp)

  return date.format('DD/MM/YYYY')
}
</script>

<template>
  <div class="review-item">
    <div
      v-if="review.text"
      class="review-comment"
    >
      <p>
        {{ review.text }}
      </p>
    </div>
    <div class="review-author">
      <NuxtImg
        loading="lazy"
        :alt="review.authorName"
        :src="review.profilePhotoUrl"
      />
      <div>
        <span class="name">
          <span>
            {{ review.authorName }} <span class="posted">{{ $t('review.posted', { date: formatDate(review.time) }) }}</span>
          </span>
        </span>
        <span class="icon">
          <template
            v-for="(star, index) in review.rating"
            :key="index"
          >
            <UiIconsStarSolid />
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.review-item {
  display: flex;
  flex-direction: column;
  white-space: normal;

  .review-comment {
    font-size: 1.3rem;
    overflow: hidden;
    margin-bottom: 1.5rem;

    p {
      margin: 0;
      height: 90px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      @media (min-width: map-get($grid-breakpoints, md)) {
        height: 80px;
      }
    }
  }

  .review-author {
    display: flex;
    gap: 1rem;
    align-items: center;

    img {
      width: 3rem;
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;

      .name {
        font-size: 0.8rem;

        .posted {
          color: var( --color-navy-550 );
        }
      }

      .icon {
        color: var(--color-yellow);
      }
    }
  }
}
</style>
